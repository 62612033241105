.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.modal .container {
  position: fixed;
  max-width: 600px;
  top: 15%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 4em 2em;
  background-color: #FFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.close {
  background-color: #181c2e;
  border: 0;
  border-radius: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  color: #FFF;
}

.close svg {
  margin-right: 5px;
}

.modal h2 {
  margin-bottom: 1.2em;
}

.modal span {
  font-weight: bold;
  font-size: 1.1em;
}

.price {
  font-weight: 700 !important;
  padding: 2px 0 !important;
}

.modal span i {
  font-weight: 400;
  margin-right: 1em;
  padding: 2px 8px;
}

.row {
  margin-bottom: 1em;
}

.modal p {
  padding-top: 0.5em;
  white-space: pre-wrap;
  line-height: 150%;
}

.status-badge {
  margin-left: 1em;
  border-radius: 2px;
}

h4 {
  margin-top: 15px;
  margin-bottom: 15px;
}
input{
  height: 30px;
}

.form-profile{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.form-profile-modal-update {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
select{
  margin-right: auto;
}