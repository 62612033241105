/* ClassificacaoPage.css */
.classificacao {
    width: 100%;
    border-collapse: collapse;
}

.classificacao-th,
.classificacao-td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}

.colocacao {
    width: 35px;
}

.pontos {
    width: 80px;
}

.top16 {
    background-color: lightgreen;
}

.line-between {
    border-bottom: 3px solid red;
}

.classificacao-tbody tr:last-child {
    border-bottom: 2px solid black;
}
.fundo{
    background-color: rgb(11, 13, 19);
}
.white{
    color: white;
}