.login-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logos-completos {
    max-width: 245px;
    margin: 15px;
    display: block;
}

form div {
    margin-bottom: 10px;
    margin-top: 10px;
}

label {
    margin: 10px;
}

input[type="text"],
input[type="password"] {
    width: calc(100% - 22px);
}

input[type="number"]{
    width: 50px;
}

button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.cadastro-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.table-container {
    max-width: 750px;
    margin: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}