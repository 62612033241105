.header {
    width: 100%;
    background-color: #494949;
    padding: 10px 16px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
}

.header div {
    background-color: #181c2e;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 150px;
}

.header div {
    padding-top: 30px;
}

.header div img {
    width: 90px;
    height: 90px;
    display: block;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
    filter: drop-shadow(2px 3px 6px #121212);
    -webkit-filter: drop-shadow(2px 3px 6px #121212);
}

.header a {
    display: block;
    padding: 16px;
    display: flex;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.7);
    flex-direction: row;
    align-items: center;
    transition: ease-in-out 0.4s;
}

.header a svg {
    margin-right: 0.5rem;
}

.header a:hover {
    background-color: #8B8B8B;
    color: #FFF;
}

.content {
    margin-left: 200px;
    padding: 1px 16px;
}

@media screen and (max-width: 700px) {
    .header {
        width: 100%;
        height: auto;
        position: relative;
    }

    div.content {
        margin-left: 0;
    }

    .header a {
        float: left;
    }

    .header div {
        display: none;
    }

    .header a svg {
        display: none;
    }
}


@media screen and (max-width: 400px) {
    .header a {
        text-align: center;
        float: none;
    }

    .header a svg {
        display: none;
    }
}